<template>
  <div>

    <article v-for="post in blogPosts" :key="post.id" 
      class="card mb-4">
      <b-card 
        no-body class="overflow-hidden">
        <b-row no-gutters>
          <b-col md="3">
            <div 
                class="bg-holder overlay" 
                :style="`background-image:url(${post.imageUrl.i});background-position: center bottom;`">
              </div>
            <b-card-img :src="post.imageUrl.i" alt="Image" class="rounded-0"
            style="max-height: 10rem;"></b-card-img>
          </b-col>
          <b-col md="9">
            <b-card-body :title="post.title" 
              class="mobile-padding">
              <b-card-text>
                <p v-html="`${post.body.slice(0, 175)} ...`"
                  class="card-text"> 
                </p>
                <div class="row no-gutters font-weight-semi-bold text-center pt-3 border-top fs--1">
                  <div class="col-auto mx-3 d-flex align-items-center">
                    <img src="@/assets/img/illustrations/like-inactive.png" width="20" alt="">
                    <span class="ml-2">
                      {{post ? post.likes : 0}}
                    </span>
                  </div>
                  <div class="col-auto mr-3 d-flex align-items-center">
                    <img src="@/assets/img/illustrations/comment-inactive.png" width="20" alt="">
                    <span class="ml-2">0</span>
                  </div>
                  <div class="col-auto mr-3 d-flex align-items-center mobile-not-display">
                    <router-link to="/blog?category=management" 
                      class="badge badge-secondary fs--0 text-uppercase">
                      {{post.category}}
                    </router-link>
                  </div>
                  <div class="col-auto mr-3 d-flex align-items-center mobile-not-display">
                    <div class="card-meta fs--1">
                      <small class="text-400">{{daysFrom(post.created)}}</small>
                    </div>
                  </div>
                  <router-link :to="`/blog/post?id=${post.id}`"
                    class="btn btn-outline-primary btn-sm ml-auto">
                    читать далее
                  </router-link>
                </div>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
    </article>
  </div>

</template>

<script>
import moment from 'moment';

export default {
  mounted() {
    this.loadBlogPosts();
  },

  computed: {
    blogPosts() {
      console.log(this.$store.getters.getBlogPostsList);
      return this.$store.getters.getBlogPostsList;
    }
  },

  methods: {
    daysFrom(date) {
      return moment().to(moment.unix(date.seconds));
    },

    async loadBlogPosts() {
      await this.$store.dispatch('fetchBlogPostsList');
    }
  }

}
</script>

<style lang="scss" scoped>
@media (max-width: 400px) {
  .mobile-not-display {
    display: none !important; 
  }

  .mobile-padding {
    padding-left: 0.7rem;
    padding-right: 0.7rem;
  }
}
</style>